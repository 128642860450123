<template>
  <div style="margin-top: 10%; text-align: center">
    <div
      class="desc"
      v-html="SettingMsg"
    />
    <div class="mt-5">
      <k-button
        @click="goBack"
        variant="primary"
        class="mb-1 mr-2"
      >
        buttons.returnToTrainingPage
      </k-button>
      <k-button
        v-if="showTackQuizButton"
        @click="onRetakeQuiz"
        variant="primary"
        class="mb-1"
      >
        buttons.retakeQuiz
      </k-button>
    </div>
  </div>
</template>
<script>
import { getPortalPageSetting } from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import { showTakeQuiz } from "@/api/training";
export default {
  data() {
    return {
      data: {},
      SettingMsg: "",
      showTackQuizButton: false
    };
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "courseInfo",
        query: {
          id: this.$route.query.courseInfoId,
        },
      });
    },
    onRetakeQuiz(){
      this.$router.push({
        name: "takeQuiz",
        query: {
          id: this.$route.query.id,
          courseInfoId: this.$route.query.courseInfoId,
        },
      });
    }
  },
  mounted() {
    this.data = JSON.parse(this.$route.query.data);

    showTakeQuiz({
      eleClassQuizId: this.$route.query.id,
      eleClassId: this.$route.query.courseInfoId
    }).then((res)=>{
      this.showTackQuizButton = res.data || false;
    })

    getPortalPageSetting(
      pageNameEnum.TrainingClassTakeQuizSubmitSuccessful
    ).then((res) => {
      var description = res.data?.description;
      for (var item of this.data) {
        description = description.replace(item.text, item.value);
      }
      this.SettingMsg = description;
    });

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
};
</script>
<style lang="scss" scoped>
.row-info {
  margin: 10px 0;
  font-weight: bold;
  font-size: 1.2rem;
}
</style>
